import { Button } from "@mui/material";
// import Footer from "../components/components164/Footer";
// import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../components/ebs/Footer";
import Header from "../components/Header";

export default function Home() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <Header />
      <div className="flex flex-col justify-center h-full gap-5 p-5">
        <Button
          style={{
            backgroundColor: "rgb(67, 60, 191)",
          }}
          onClick={() => handleClick("/ebs")}
          variant="contained"
          disableElevation
        >
          EBS V1
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(80, 90, 191)",
          }}
          onClick={() => handleClick("/ebs-v2")}
          variant="contained"
          disableElevation
        >
          EBS V2
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(27, 95, 168)",
          }}
          onClick={() => handleClick("/vertigo")}
          variant="contained"
          disableElevation
        >
          VERTIGO
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(15, 40, 130)",
          }}
          onClick={() => handleClick("/cse")}
          variant="contained"
          disableElevation
        >
          CSE
        </Button>
      </div>
      <Footer />
    </>
  );
}
